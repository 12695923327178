import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { bootstrapPublicPath } from './set-public-path'
import State from './App.state'
import Locale from './App.locale'
import Theme from './App.theme'
import YupLocale from './App.yup.locale'
import { Router } from 'react-router'
import { history } from './App'

const Maintenance = React.lazy(() => import('./views/Maintenance'))

export const RegisterMaintenance = singleSpaReact({
  React,
  ReactDOM,
  // eslint-disable-next-line react/display-name
  rootComponent: () => (
    <div>
      <Suspense fallback="loading">
        <State>
          <Theme>
            <Locale>
              <YupLocale>
                <Router history={history}>
                  <Maintenance />
                </Router>
              </YupLocale>
            </Locale>
          </Theme>
        </State>
      </Suspense>
    </div>),
  errorBoundary() {
    return <div>Unavailable resource</div>
  }
})

bootstrapPublicPath()
